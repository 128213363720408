<template>
<div class="h-full overflow-y-scroll">
  <div class="relative  flex h-full mb-40 flex-col justify-center ">
    <div class="box-request px-4" v-if="!isSendAd">
      <h3>کـاربر عـزیز درخواسـت بازدید از مـلکت برای نزدیک‌ترین بنگـاه داران، ارسال خواهد شد، آن ها در زمـان تعیین شده توسـط خودت با هــماهــنگی قــبلــی به مـــلکت ســر مــیزنند</h3>
      <div class="flex flex-col justify-between items-center">
        <p>انتخاب شعاع ارسال درخواست از مکان بازدید</p>
        <toggle-switch
            class="toggle-button"
            :options="myOptions"
            v-model="radius"
        />
        <button class="btn-custom w-full mt-8" @click="submitRadius" >
          ارسال به بنگاه های اطراف
          <span class="svg-btn-icon2 bg-orange transform rotate-0">
          <svg xmlns="http://www.w3.org/2000/svg" width="20.826" height="20.826" viewBox="0 0 20.826 20.826">
  <path id="ic_arrow_back_24px" d="M24.826,13.111H8.985l7.276-7.276L14.413,4,4,14.413,14.413,24.826l1.835-1.835L8.985,15.715H24.826Z" transform="translate(-4 -4)" fill="#fafafa"/>
</svg>
        </span>
        </button>
      </div>
    </div>

    <div class="box-request px-4">
      <h3>اگه دوست داشته باشی میتونی
        درخواستت رو به طور مستقیم
        برای سه بنگا بفرستی</h3>
      <div class="flex justify-between items-center">
        <span>اینجا بنگاه رو جستجو کن</span>
        <router-link :to="{name : 'selectRealestate'}" class="add">
          <svg xmlns="http://www.w3.org/2000/svg" width="29.588" height="19.353" viewBox="0 0 29.588 19.353">
            <g id="Group_12914" data-name="Group 12914" transform="translate(-33.006 -164.331)">
              <path id="ic_location_city_24px" d="M15,11V5L12,2,9,5V7l.147,14H21V11Zm-2,8H11V17h2Zm0-4H11V13h2Zm0-4H11V9h2Zm0-4H11V5h2Zm6,12H17V17h2Zm0-4H17V13h2Z" transform="translate(24.006 162.331)" fill="#ff7400"/>
              <path id="Union_2" data-name="Union 2" d="M10.686,10.719l3.147,3.156ZM0,6.188a6.168,6.168,0,1,1,6.168,6.188A6.178,6.178,0,0,1,0,6.188Z" transform="translate(48.407 169.455)" fill="none" stroke="#ff7400" stroke-width="1"/>
            </g>
          </svg>

        </router-link>
      </div>
    </div>
    <NavigationButtonCustomer class="z-999 relative"/>
  </div>
</div>
</template>

<script>
import Vue from 'vue'

import NavigationButtonCustomer from '../../components/navigationButton/CustomerButton'
import ToggleSwitch from 'vuejs-toggle-switch'
import axios from "../../plugins/axios";
Vue.use(ToggleSwitch)
export default {
name: "successRequest",
  components:{
    NavigationButtonCustomer
  },
  data() {
    return {
      radius : null,
      myOptions: {
        items: {
          preSelected: 'یک کیلومتری',
          labels: [
            {name: 'دو کیلومتری'},
            {name: 'یک کیلومتری'},
            {name: 'پونصد متری'},
            {name: 'ارسال نشود'},
          ]
        }

      },
      isSendAd : false
    }
  },
  methods :{
    checkRadius(data){
      switch (true){
        case data === 'ارسال نشود':
          return 0
        break;
        case data === 'پونصد متری':
          return 500
          break;
        case data === 'یک کیلومتری':
          return 1000
          break;
        case data === 'دو کیلومتری':
          return 2000
          break;
      }
    },
    submitRadius(){
      if (this.checkRadius(this.radius) !== 0){
        var data = this.$store.state.addRequest
        axios.post('/ad-requests', {
          ...data,
          radius : this.checkRadius(this.radius)
        }).then(({data})=>{
          if (data.data.length < 0){
            this.$toast.error(data.message)
          }else {
            this.$toast.success(data.message)
            this.isSendAd = true
          }
        })
      }else{
        this.isSendAd = true
      }
    }
  },
  mounted() {
  this.$store.commit('general/CHANGE_IS_SHOW_BACK' ,false)

  }

}
</script>

<style lang="scss" >
p{
  line-height: 1.8em;
}
.box-request {
  background: #542D5B;
  padding: 1rem 2rem 2rem 2rem;
  z-index: 99;
  margin-top: 2rem;
  border-radius: 0.8rem;
  color: #fff;
  h3 {
    line-height: 1.8em;
  }
  div {
    width: 100%;
    span{
      font-size: 13px;
    }
    .add{
      background: #FF7400;
      background: radial-gradient(circle, rgba(255, 116, 0, 0.572164) -117%, #210726 105%);
      padding: 1rem 0.8rem;
      border-radius: 0.8rem;
    }
    margin-top: 2rem;
  }
}
.toggle-switch {
  width: 100% !important;
  direction: ltr;
  border: 1px solid #FF7400 !important;
  border-radius: 1rem;
  height: 2.9rem !important;
}

.toggle-switch li label {
  background: transparent !important;
  border: none !important;
  transition: revert !important;

}

.toggle-switch li label.active {
  color: white !important;
  font-size: 14px !important;
  font-family: 'Anjoman' !important;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2.7rem;
}

.toggle-switch li label.selected {
  background: #644B92 !important;
  border-radius: 1rem !important;
  font-weight: normal !important;
  margin: 0.1rem 0;
  height: 2.7rem !important;
  top: -1px !important;
}
</style>